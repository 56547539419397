.appBarItem {
  display: inline;
  margin-left: 2rem;
  cursor: pointer;
}

.appBarItem > .active {
  border-bottom: 3px solid red;
  color: red;
}

.drawerItem {
  display: inline;
  cursor: pointer;
}

.drawerItem > .active {
  border-bottom: 3px solid red;
  color: red;
}
